import React, { useState } from 'react';
import { Box, Button, Text, Flex } from 'rebass';
import { useTranslation } from 'react-i18next';
import { useIdentityContext } from 'react-netlify-identity';
import ScaleLoader from "react-spinners/ScaleLoader";
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/react-hooks';
import { gql  } from 'apollo-boost';
import { useFormContext } from 'react-hook-form';

import SEO from '../components/seo';
import { Layout } from '../layout';
import { Container } from '../components/container';
import { Header } from '../components/Header';
import Alert from '../components/Alert';
import Form, { Input } from '../components/Form';
import i18n from '../i18n';
import PasswordPopOver from '../components/PasswordPopOver'
import { client } from '../graphql/client';
import { validatePasswordPolicy } from '../utils';

const UPDATE_PASSWORD = gql`
  mutation UpdateAccountPass(
    $accountId: ID!
    $password: String!
  ) {
    updateAccountPassword(
      accountId: $accountId
      password: $password
    ) {
      _id
      password
    }
  }
`;

const ChangePasswordForm = ({loadingIndicator})=> {
  const { t } = useTranslation();
  const { register, errors, getValues } = useFormContext();
  const [showTooltip, setShowTooltip] = useState(false);
  return (
  <>
    <PasswordPopOver showTooltip={showTooltip}>
      <Input
        title={t('my_boxit.account_details.password')}
        name="password"
        type="password"
        autoComplete="off"
        onFocus={()=>{setShowTooltip(true)}}
        onBlur={()=>{setShowTooltip(false)}}
        ref={register({ 
          required: true,
          validate: {
            passwordPolicy: (value)=> {
              const validation = validatePasswordPolicy(value)
              return validation.errorMessage ? t(`validation.password.${validation.errorMessage}`) : true
            },
            passwordMisMatch: value => {
              const formValues = getValues();
              return formValues['confirmPassword'] === value || t('validation.passwordMisMatch')
            }
          }
        })}
      />
    </PasswordPopOver>
    <Input
      title={t('my_boxit.account_details.confirmPassword')}
      name="confirmPassword"
      type="password"
      autocomplete="off"
      ref={register({
        required: true,
        validate: {
          passwordMisMatch: value => {
            const formValues = getValues();
            return formValues['password'] === value || t('validation.passwordMisMatch')
          }
        }
      })}
    />
     { loadingIndicator ?
            (<Box textAlign="center"> 
              <ScaleLoader
                css={{}}
                size={40}
                color={"#01549b"}
                loading={loadingIndicator}
              />
            </Box>) :
            (<Button
              py="8px"
              bg="blues.peacock"
              css={{ borderRadius: '0 4px 4px 0' }}
              width={[1]}
            >
            {t('password_recovery.button')}
            </Button>)
      }
  </> 
  );
}


const PasswordRecoveryPage = props => {
  const { t } = useTranslation();
  const [alertMsg, setAlertMsg] = useState(null);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const defaultToken = (props.location.state && props.location.state.token)?props.location.state.token : null;
  const [token] = useState(defaultToken);
  const [accountExists, setAccountExists] = useState(false);
  const { isLoggedIn, updateUser, setUser, user, logoutUser, recoverAccount,} = useIdentityContext();

  const [updatePassword] = useMutation(UPDATE_PASSWORD, {
    onError: error => {
      setLoadingIndicator(false)
      setAlertMsg(t('validation.failed_to_change_password'));
    },
    onCompleted: async (data) => {
      const {updateAccountPassword: {password}} = data;
      try{
        await updateUser({password: password});
        setLoadingIndicator(false)
        await logoutUser();
        client.clearStore()
        navigate((i18n.language === 'en')? `${'/'+i18n.language}/sign_in`: '/sign_in');
      }catch(e){
        setLoadingIndicator(false);
        setAlertMsg(t('validation.failed_to_change_password'));
      }
    },
  });

  const navigateToHome = ()=>{
    if (typeof window !== `undefined`){
      navigate(`/`);
    }
  }

  const recoverMyAccount = async(t)=>{
    try{
        const acc = await recoverAccount(t)
        setAccountExists(true);
        setUser(acc);
      }catch(err){
        setAlertMsg("Error retriving Account");
        console.log('errro Recovering MyAccount', err)
      }
  }

  if(!token){
    navigateToHome();
    return null;
  }

  if(!accountExists && alertMsg === null){
    if(!isLoggedIn && token){
      recoverMyAccount(token)
    }
  }

  const handleSubmit = (data)=>{
    setLoadingIndicator(true)
    setAlertMsg(null);
    data.accountId = user.user_metadata.aid;
    delete data.confirmPassword;
    updatePassword({
      variables: data,
    })
  }
  
  return (
    <Layout {...props}>
      <SEO title="PASSWORD RECOVERY" keywords={['weboxit', 'boxit']} />
      <Header
        title={t('password_recovery.title')}
        subtitle={t('password_recovery.subtitle')}
      />
      <Container>
        <Box pt="70px" mx="auto">
          <Alert message={alertMsg} />
        </Box>
        <Flex
          pt="60px"
          width={[1, '300px']}
          flexWrap="wrap"
          mx="auto"
          flexDirection="column"
        >
          {accountExists &&
            <Form onSubmit={handleSubmit} autoComplete="off">
              <ChangePasswordForm loadingIndicator={loadingIndicator} />
            </Form>  
          }
        </Flex>
        <Text py="56px" fontSize="20px" textAlign="center" color="marine">
          {t('password_recovery.please_enter_your_password')}
          <br />
          {t('password_recovery.thank_you')}
        </Text>
      </Container>
    </Layout>
  );
};

export default PasswordRecoveryPage;
